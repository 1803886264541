<script setup lang="ts">

import {useFetch} from "#app";

const contactStore = useContactStore();

const errors = reactive<{
	name: string|null,
	job: string|null,
	email: string|null,
	phone: string|null,
	message: string|null,
	general: string|null,
}>({
	name: null,
	job: null,
	email: null,
	phone: null,
	message: null,
	general: null,
});

const handleSubmit = async () => {
	resetErrors();

	if (!contactStore.name) errors.name = 'Name is required';
	if (!contactStore.message) errors.message = 'Message is required';
	if ((!contactStore.email) && (!contactStore.phone)) {
		errors.email = 'Email OR Phone Number is required';
		errors.phone = 'Email OR Phone Number is required';
	}

	if (Object.values(errors).every((error) => error === null)) {
		let body = {
			name: contactStore.name,
			message: contactStore.message,
		};
		if (contactStore.job) body.jobTitle = contactStore.job;
		if (contactStore.email) body.email = contactStore.email;
		if (contactStore.phone) body.phoneNumber = contactStore.phone;
		const {data, error} = await useFetch('/api/contact', {
			method: "POST",
			body: body,
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			}
		});
		if (error.value) {
			console.error(error.value);
			errors.general = "There was a problem please try later."
			return;
		}else {
			if (data.value) {
				contactStore.alreadySent = true;
				resetErrors();
			}
		}
	}
};


const resetErrors = () => {
	errors.name = null;
	errors.job = null;
	errors.email = null;
	errors.phone = null;
	errors.message = null;
	errors.general = null;
};

</script>

<template>
	<div class="mx-auto text-center">
		<h2 class="text-2xl font-semibold text-black mb-8">Contact Us</h2>
		<p class="text-lg font-light text-black text-justify px-0 lg:px-24 xl:px-72 mb-12">We’d love to hear from you and explore how we can help your organization thrive with our solutions. Whether you're interested in partnering with us or looking for investment opportunities, feel free to reach out and let's start a conversation.</p>
		<form class="px-0 lg:px-24 xl:px-72 mx-auto flex md:flex-row flex-col flex-wrap gap-x-5 gap-y-8" autocomplete="on" @submit.prevent="handleSubmit">
			<div class="relative md:w-1/3 flex-grow ">
				<input type="text" v-model="contactStore.name" autocomplete="name" name="name" class="w-full p-3 border border-gray-400 rounded-lg" placeholder="Name">
				<NuxtImg v-if="errors.name" src="/icons/warning.svg" width="24" height="24" alt="warning-logo"
				         class="absolute right-4 top-3 w-6 h-6 text-red-500 [filter:brightness(0)_saturate(100%)_invert(11%)_sepia(92%)_saturate(6841%)_hue-rotate(3deg)_brightness(107%)_contrast(113%)]" />
				<p v-if="errors.name" class="text-start text-[#FF0000] text-sm mt-1">{{ errors.name }}</p>
			</div>
			<div class="relative md:w-1/3 flex-grow ">
				<input type="email" v-model="contactStore.email" autocomplete="email" name="email" class="w-full p-3 border border-gray-400 rounded-lg" placeholder="Email">
				<NuxtImg v-if="errors.email" src="/icons/warning.svg" width="24" height="24" alt="warning-logo"
				         class="absolute right-4 top-3 w-6 h-6 text-red-500 [filter:brightness(0)_saturate(100%)_invert(11%)_sepia(92%)_saturate(6841%)_hue-rotate(3deg)_brightness(107%)_contrast(113%)]" />
				<p v-if="errors.email" class="text-start text-[#FF0000] text-sm mt-1">{{ errors.email }}</p>
			</div>
			<div class="relative md:w-1/3 flex-grow ">
				<input type="text" v-model="contactStore.phone" autocomplete="tel" name="phone-number" class="w-full p-3 border border-gray-400 rounded-lg" placeholder="Phone">
				<NuxtImg v-if="errors.phone" src="/icons/warning.svg" width="24" height="24" alt="warning-logo"
				         class="absolute right-4 top-3 w-6 h-6 text-red-500 [filter:brightness(0)_saturate(100%)_invert(11%)_sepia(92%)_saturate(6841%)_hue-rotate(3deg)_brightness(107%)_contrast(113%)]" />
				<p v-if="errors.phone" class="text-start text-[#FF0000] text-sm mt-1">{{ errors.phone }}</p>
			</div>
			<div class="relative md:w-1/3 flex-grow ">
				<input type="text" v-model="contactStore.job" autocomplete="organization-title" name="job" class="w-full p-3 border border-gray-400 rounded-lg" placeholder="Job title">
				<NuxtImg v-if="errors.job" src="/icons/warning.svg" width="24" height="24" alt="warning-logo"
				         class="absolute right-4 top-3 w-6 h-6 text-red-500 [filter:brightness(0)_saturate(100%)_invert(11%)_sepia(92%)_saturate(6841%)_hue-rotate(3deg)_brightness(107%)_contrast(113%)]" />
				<p v-if="errors.job" class="text-start text-[#FF0000] text-sm mt-1">{{ errors.job }}</p>
			</div>
			<div class="relative w-full flex-grow ">
				<textarea type="text" v-model="contactStore.message" name="message" class="w-full resize-y p-3 border border-gray-400 rounded-lg" rows="4" placeholder="Message"></textarea>
				<NuxtImg v-if="errors.message" src="/icons/warning.svg" width="24" height="24" alt="warning-logo"
				         class="absolute right-4 top-3 w-6 h-6 text-red-500 [filter:brightness(0)_saturate(100%)_invert(11%)_sepia(92%)_saturate(6841%)_hue-rotate(3deg)_brightness(107%)_contrast(113%)]" />
				<p v-if="errors.message" class="text-start text-[#FF0000] text-sm mt-1">{{ errors.message }}</p>
				<p v-if="errors.general" class="text-start text-[#FF0000] text-sm mt-1">{{ errors.general }}</p>
			</div>
			<button type="submit" :disabled="contactStore.alreadySent" class="mx-auto w-1/2 p-3 bg-secondary cursor-pointer disabled:bg-gray-500 disabled:cursor-not-allowed text-white rounded-lg">Send Message</button>
		</form>
	</div>
</template>

<style scoped lang="postcss">
input[type=text], input[type=email], textarea {
	@apply focus:ring-black focus:ring-offset-black focus:shadow-dark focus:border-black;
}
</style>
